import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect'

class Hero extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0
        }
    }

    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    
    componentDidMount() {
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            background-size: cover;
            background-repeat: no-repeat;
        `

        const Overlay = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            background-color: rgba(0,0,0,.5);
        `

        const Heading1 = styled.h1`
            font-size: 80px;
            font-family: Teko;
            line-height: 40px;
            color: #fff;
            font-weight: 400;
            text-transform: uppercase;
            @media (min-width:768px) {
                margin-left: 4px;
            }
            @media (max-width:767px) {
                margin-left: 2px;
            }
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 40px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
        `
        const Heading4 = styled.h4`
            color: #fff;
            font-weight: 600;
            font-size: 25px;
        `
        const SubHeading = styled.h5`
        color: #fff;
        font-size: 20px;
        margin-left: 10px;
        font-weight: 400;
        text-transform: uppercase;
        @media (max-width:767px) {
            font-size: 15px;
        }
    `
        const Heading2 = styled.h2`
            font-size: 120px;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            background: -webkit-linear-gradient(left, #24e5af , #007a7a);
            background: -o-linear-gradient(right, #24e5af, #007a7a);
            background: -moz-linear-gradient(right, #24e5af, #007a7a);
            background: linear-gradient(to right, #24e5af , #007a7a); 
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
            @media (max-width:767px) {
                font-size: 40px;
                line-height: 40px;
            }
           @media (min-width:768px) and (max-width:1500px) {
                font-size: 70px;
            }
        `

        const Type = styled.div`
            font-size: 50px;
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            @media (min-width:768px) {
                margin-left: 6px;
            }
            @media (max-width:767px) {
                margin-left: 2px;
            }
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: Teko;
            }
        `
        const Content = styled.div`
            position: relative;
            z-index: 10
        `

        const Separator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #24e5af;
            margin-bottom: 30px;
            @media (min-width:768px) {
                margin-left: 6px;
            }
            @media (max-width:767px) {
                margin-left: 2px;
            }
        `

        const BottomContent = styled.div`
            position: absolute;
            width: 100%;
            z-index: 2;
            bottom: -13%;
            @media (min-width:1025px) and (max-width: 1400px) {    
                bottom: -35%;
            }
            @media (max-width:767px) {
                position: relative;
                bottom: 0;
            } 
            @media (min-width:768px) and (max-width: 1023px) {    
                bottom: -25%;
            }
        `

        const ServiceCol = styled(Col)`
            padding: 0;
            border-right: #444;
            &.no-border {
                border-right: none;
            }
            @media (max-width:500px) {
                border-right:none;
            }
        `

        const Service = styled.div`
            min-height: 300px;
            background-color: #111;
            transition: .2s;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &:hover {
                background-color: #222;
            }
        `
        const ServiceContent = styled.div`
            padding: 40px;
            height: 340px;
            color: #fff;
        `
        const ServiceHeading = styled.h4`
            font-size: 30px;
            font-weight: 400;
            font-family: Teko;
        `
        const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #24e5af;
            margin-bottom: 10px;
        `

        const ServiceIcon = styled.div`
            margin-bottom: 20px;
            img {
                max-height: 70px;
            }
        `

        const ServiceText = styled.p`
            font-size: 14px;
            font-weight: 300;
            color: #c5c5c5;
        `

        const LogoWrapper = styled.div`
            flex: 0 0 20%;
            max-width: 20%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 500px) {
                flex: 0 0 50%;
                max-width: 50%;
                justify-content: center;
            }
        `

        const Logo = styled.img`
            height: 60px;
            padding: auto;
            margin-left: auto;
            margin-right: auto;
            padding-bottom:10px;
            padding-top:5px;
            @media (max-width: 1023px) {
                height: 30px;
            }
        `
        
        return (
                <Section id="home">
                    <Overlay style={{height: `${this.state.width > 500 ? this.state.height : 350}px`}}>
                        <Container>
                            <Content>
                                <SubHeading>
                                    Udviklingshus for iværksættere og visionære
                                </SubHeading>
                                <Heading2>
                                    App Libitum
                                </Heading2>
                                <Separator/>
                                <Type>
                                    <div style={{float: 'left', margin:'3px', fontFamily:'teko'}}>Vi udvikler </div>
                                    <Typewriter
                                        options={{
                                            strings: [
                                                'Apps',
                                                'Hjemmesider',
                                                'Backend-systemer',
                                                'CMS-systemer',
                                        ],
                                        autoStart: true,
                                        loop: true,
                                        }}
                                        />
                                        
                                </Type>
                            </Content>
                        </Container>
                    </Overlay>
                    <BottomContent>
                            <Container>
                                <Row>
                                    <ServiceCol md={4} style={{borderRight: this.state.width > 500 ? "1px solid #444" : "none"}}>
                                        <Service>
                                            <ServiceContent>
                                                <ServiceIcon>
                                                    <img src={this.props.webexpertIcon.childImageSharp.fluid.src} alt="app-udvikling" />
                                                </ServiceIcon>
                                                <ServiceHeading>Apps</ServiceHeading>
                                                <ServiceSeparator/>
                                                <ServiceText>
                                                    Vores cross-platform apps bygges på én kodebase. Det betyder at du både får en iOS og Android-app.
                                                    Derfor sparer vi en masse udviklingstimer og du får en app som ser ens ud på begge platforme.
                                                </ServiceText>
                                            </ServiceContent>
                                        </Service>
                                    </ServiceCol>
                                    <ServiceCol md={4} style={{borderRight: this.state.width > 500 ? "1px solid #444" : "none"}}>
                                        <Service>
                                            <ServiceContent>
                                                <ServiceIcon>
                                                        <img src={this.props.mobileIcon.childImageSharp.fluid.src} alt="backend-udvikling" />
                                                </ServiceIcon>
                                                <ServiceHeading>Backend</ServiceHeading>
                                                <ServiceSeparator/>
                                                <ServiceText>
                                                    Enhver app har brug for en stærk backend der kan håndterer en masse data. <br/>
                                                    Vi udvikler en solid backend som hurtigt giver din app indhold.
                                                </ServiceText>
                                            </ServiceContent>
                                        </Service>
                                    </ServiceCol>
                                    <ServiceCol md={4} style={{borderRight: this.state.width > 500 ? "1px solid #444" : "none"}}>
                                        <Service>
                                            <ServiceContent>
                                                <ServiceIcon>
                                                        <img src={this.props.seoIcon.childImageSharp.fluid.src} alt="cms-udvikling" />
                                                </ServiceIcon>
                                                <ServiceHeading>CMS</ServiceHeading>
                                                <ServiceSeparator/>
                                                <ServiceText>
                                                    Pakken bliver fuldendt med et Content Management System til håndtering af data. 
                                                    Dette muliggører at du kan administrere produktets data, på en enkel og overskuelig CMS-website.
                                                </ServiceText>
                                            </ServiceContent>
                                        </Service>
                                    </ServiceCol>
                                </Row>
                            </Container>
                    </BottomContent>
                </Section>
        )
    }
}
export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "banner2neon.png"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              src
            }
          }
        }
        webexpertIcon: file(relativePath: {eq: "icons/iconapp.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobileIcon: file(relativePath: {eq: "icons/iconbackend.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seoIcon: file(relativePath: {eq: "icons/iconcms.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
      `}
      render={({ 
        background, 
        webexpertIcon, 
        mobileIcon, 
        seoIcon}) => <Hero  
        background={background} 
        webexpertIcon={webexpertIcon} 
        mobileIcon={mobileIcon} 
        seoIcon={seoIcon}
        {...props} />}
    />
  )